import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { reactive } from '@vue/composition-api'
import { TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const UPDATE_PAGE_TITLE = 'Quote RO1039--- john Doe Films, Inc.'
  const QUOTE_INFORMATION_TITLE = 'Quote Information'
  const EVENT_INFORMATION_TITLE = 'Event Information'
  const DISPATCH = 'Dispatch'
  const RETURN = 'Return'
  const RENTAL_ITEMS = 'Rental Items'
  const CAMERAS_LENSES = 'Cameras lenses'
  const LIGHTING = 'Lighting'
  const SALES = 'Sales'
  const OTHER_CHARGES = 'Other charges'
  const TOTALS = 'Totals'
  const INTERNAL_INFORMATION = 'Internal information'
  const ACTIVITY_LOG = 'Activity log'
  const ORDER_NOTES = 'Order notes'
  // ////////////////////
  const quoteInfoBillingTitle = 'Billing'
  const quoteInfoBilling = [
    {
      key: 'supplier.cust_name_dba',
      label: 'DBA',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Contact',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Phone',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Email',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Address',
    },
  ]
  const quoteInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Supplier',
    },
    {
      key: 'dispatch_warehouse.label',
      label: 'Dispatch Warehouse',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'required_by', '—')}`,
      label: 'Required by',
    },
    {
      key: 'dispatch_method.name',
      label: 'Dispatch Method',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'billing_contact.first_name', '—')} ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name', '')}`,
      label: 'Billing Contact',
    },
    {
      key: data => `${getValueFromGivenObjectByKey(data, 'dispatch_contact.firstname', '—')} ${getValueFromGivenObjectByKey(data, 'dispatch_contact.lastname', '')}`,
      label: 'Dispatch Contact',
    },
    {
      key: 'billing_contact.cell_no',
      label: 'Billing Phone',
    },
    {
      key: 'dispatch_contact.phone',
      label: 'Dispatch Phone',
    },
    {
      key: 'billing_contact.email',
      label: 'Billing Email',
    },
    {
      key: 'dispatch_contact.email',
      label: 'Dispatch Email',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')} 
      ${getValueFromGivenObjectByKey(data, 'billing_address.city', '')}
      ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '')}
      `,
      label: 'Billing Address',
    },
    {
      key: data => `
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.street', '—')} 
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.name', '')}
      ${getValueFromGivenObjectByKey(data, 'dispatch_warehouse.country.two_letter', '')}
      `,
      label: 'Dispatch Address',
    },
  ]
  const eventInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Event Referens',
      value: 'Zoe Deschannel Shoot San Diego',
    },
  ]
  const eventChildInformationObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Received Via',
      value: 'Web',
    },
    {
      key: 'supplier.cust_name_dba',
      label: 'Placed By',
      value: 'Trisha Ziff',
    },
    {
      key: 'supplier.cust_name_dba',
      label: 'Placed On',
      value: 'September 20, 2022 6:51 pm',
    },
  ]
  const dispatchObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Pick up date',
      value: 'Sept. 20, 2022, 09:30 am',
    },
    {
      key: 'supplier.cust_name_dba',
      label: 'Dispatch method',
      value: 'Zoe Deschannel Shoot San Diego',
    },
    {
      key: 'supplier.cust_name_dba',
      label: 'Dispatch Address ',
      value: 'Brooklyn Warehouse'
          + '154 Bogart Street Brooklyn NY 11206 - Drive In Loading Area',
    },
  ]
  const returnObjectForRender = [
    {
      key: 'supplier.cust_name_dba',
      label: 'Return date',
      value: 'Sept. 20, 2022, 09:30 am',
    },
    {
      key: 'supplier.cust_name_dba',
      label: 'Return method',
      value: 'Warehouse Drop Off',
    },
    {
      key: 'supplier.cust_name_dba',
      label: 'Return Address',
      value: 'Brooklyn Warehouse\n'
          + '154 Bogart Street Brooklyn NY 11206 - Drive In Loading Area',
    },
  ]
  const tableColumnsCatalog = reactive([
    {
      key: 'actions',
      label: '',
      thStyle: { width: '12%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '40%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '18%' },
    },
    {
      key: '2',
      label: 'Stock',
      thStyle: { width: '10%' },
    },
    {
      key: '1',
      label: 'Other Loc.',
      thStyle: { width: '10%' },
    },
    {
      key: '3',
      label: 'Total',
      thStyle: { width: '10%' },
    },
  ])
  const dispatchAndReturnFields = {
    internal_shipping_notes: {
      type: TEXT_INPUT,
      label: '',
      placeholder: 'Notes added',

    },
  }
  return {
    UPDATE_PAGE_TITLE,
    EVENT_INFORMATION_TITLE,
    DISPATCH,
    RETURN,
    eventInformationObjectForRender,
    eventChildInformationObjectForRender,
    dispatchObjectForRender,
    returnObjectForRender,
    RENTAL_ITEMS,
    CAMERAS_LENSES,
    LIGHTING,
    SALES,
    OTHER_CHARGES,
    TOTALS,
    INTERNAL_INFORMATION,
    ACTIVITY_LOG,
    ORDER_NOTES,
    QUOTE_INFORMATION_TITLE,
    quoteInformationObjectForRender,
    tableColumnsCatalog,
    dispatchAndReturnFields,
  }
}
