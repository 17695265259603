<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(UPDATE_PAGE_TITLE) }}
      </h3>
    </portal>
    <order-information class="mb-1" />
    <div class="form-wrapper">
      <div
        v-b-toggle="'add-primary-contact'"
        @click="toggle('form-piece-appear-on-customer-select', 'FormPieceAppearOnCustomerSelect')"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            id="form-piece-appear-on-customer-select"
            icon="LArrowDownIcon"
            class="feather-chevron when-opened mr-1"
            style="transform: rotate(-90deg)"
          />
          <h4 class="font-weight-bolder text-body m-0">
            {{ $t(CUSTOMER_INFORMATION_FORM_TITLE) }}
          </h4>
        </div>
      </div>

      <b-collapse
        :id="'add-primary-contact'"
        ref="add-primary-contact"
        visible
      >
        <form-piece-appear-on-customer-select />
      </b-collapse>
    </div>
    <div class="form-wrapper mt-1">
      <div
        v-b-toggle="'dispatch-and-return'"
        @click="toggle('dispatch-and-return', 'DispatchAndReturn')"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            id="dispatch-and-return"
            icon="LArrowDownIcon"
            class="feather-chevron when-opened mr-1"
            style="transform: rotate(-90deg)"
          />
          <h4 class="font-weight-bolder text-body m-0">
            {{ $t(DISPATCH_FORM_TITLE) + ' & ' + $t(RETURN_FORM_TITLE) }}
          </h4>
        </div>
      </div>

      <b-collapse
        :id="'dispatch-and-return'"
        ref="dispatch-and-return"
        visible
      >
        <dispatch-and-return />
      </b-collapse>
    </div>
    <div class="form-wrapper mt-1">
      <div
        v-b-toggle="'event-information'"
        @click="toggle('event-information', 'EventInformation')"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            id="event-information"
            icon="LArrowDownIcon"
            class="feather-chevron when-opened mr-1"
            style="transform: rotate(-90deg)"
          />
          <h4 class="font-weight-bolder text-body m-0">
            {{ $t(EVENT_INFORMATION_FORM_TITLE) }}
          </h4>
        </div>
      </div>

      <b-collapse
        :id="'event-information'"
        ref="event-information"
        visible
      >
        <event-information />
      </b-collapse>
    </div>
    <order-items class="mt-1" />
    <div class="form-wrapper mt-1">
      <div
        v-b-toggle="'other-charges-table'"
        @click="toggle('other-charges-table', 'OtherChargesTable')"
      >
        <div class="d-flex align-items-center">
          <feather-icon
            id="other-charges-table"
            icon="LArrowDownIcon"
            class="feather-chevron when-opened mr-1"
            style="transform: rotate(-90deg)"
          />
          <h4 class="font-weight-bolder text-body m-0">
            {{ $t(OTHER_CHARGES_FORM_TITLE) }}
          </h4>
        </div>
      </div>
      <b-collapse
        :id="'other-charges-table'"
        ref="other-charges-table"
        visible
      >

        <other-charges-table />
      </b-collapse>
    </div>
    <totals class="mt-1" />

  </div>
</template>

<script>

import OrderInformation
from '@/views/main/orders/view/new-rental-sales/components/create/OrderInformation.vue'
import FormPieceAppearOnCustomerSelect
from '@/views/main/orders/view/new-rental-sales/create/FormPieceAppearOnCustomerSelect.vue'
import DispatchAndReturn
from '@/views/main/orders/view/new-rental-sales/components/create/DispatchAndReturn.vue'
import { BCollapse, VBToggle } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import pgConfig from '@/views/main/orders/view/new-rental-sales/create/config'
import EventInformation from '@/views/main/orders/view/new-rental-sales/components/create/EventInformation.vue'
import OrderItems from '@/views/main/orders/view/new-rental-sales/components/create/OrderItems.vue'
import OtherChargesTable from '@/views/main/orders/view/new-rental-sales/components/create/OtherChargesTable.vue'
import Totals from '@/views/main/orders/view/new-rental-sales/components/Totals.vue'
import config from './config'

export default {
  name: 'QuoteUpdate',
  components: {
    Totals,
    OtherChargesTable,
    OrderItems,
    EventInformation,
    BCollapse,
    FormPieceAppearOnCustomerSelect,
    OrderInformation,
    DispatchAndReturn,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    toggle(id, collapseToggler) {
      this[collapseToggler] = !this[collapseToggler]
      if (this[collapseToggler]) {
        document.getElementById(id).style.transform = 'rotate(0)'
        document.getElementById(id).style.transition = 'all 0.2s ease'
      } else {
        document.getElementById(id).style.transform = 'rotate(-90deg)'
      }
    },
  },
  setup() {
    const { UPDATE_PAGE_TITLE } = config()
    const {
      CUSTOMER_INFORMATION_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      EVENT_INFORMATION_FORM_TITLE,
      OTHER_CHARGES_FORM_TITLE,
    } = pgConfig()
    return {
      UPDATE_PAGE_TITLE,
      CUSTOMER_INFORMATION_FORM_TITLE,
      DISPATCH_FORM_TITLE,
      RETURN_FORM_TITLE,
      EVENT_INFORMATION_FORM_TITLE,
      OTHER_CHARGES_FORM_TITLE,

    }
  },
}
</script>
